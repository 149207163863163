<template>
  <!--    Category Form-->
  <validation-observer
      ref="categoryForm"
      v-slot="{invalid}"
  >
    <lenon-modal
        :title="`${updateCategoryMode?'Update':'Create'} Category`"
        :show="categoryModalOpened"
        :show-overlay="deletingCategory"
        @onClose="closeCategoryModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
              v-model="selectedCategoryId"
              placeholder="All Categories"
              :options="categories"
              label-name="name"
              value-name="id"
              @input="populateCategoryForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
              icon="TrashIcon"
          >
            <b-dropdown-item @click="deleteCategory()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
              variant="flat-danger"
              icon="XIcon"
              label=""
              tool-tip-text="Clear Form"
              @onClick="resetCategoryForm()"
          />
        </div>
      </b-row>
      <error-display :error="error" />
      <lenon-input
          v-model="categoryName"
          name="name"
          placeholder="Enter category name"
          rules="required"
          label="Category Name"
      />
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
              variant="flat-danger"
              icon="XIcon"
              class="mr-1"
              label="Cancel"
              @onClick="closeCategoryModal()"
          />
          <lenon-button
              :label="updateCategoryMode?'Update':'Create'"
              :disabled="invalid"
              :loading="categoryLoading"
              loading-text="Loading..."
              @onClick="updateCategoryMode?updateCategory():createCategory()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>
import { BRow, BDropdownItem, BDropdownDivider } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import { CREATE_TERM_M, DELETE_TERM_M, UPDATE_TERM_M } from '@/graphql/mutations'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import ErrorDisplay from '@/lenon/components/ErrorDisplay'
import logData from '@/libs/log'

export default {
  name: 'ExpenseCategorySetup',
  components: {
    ErrorDisplay,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
  },
  mixins: [showToast],
  props: {
    modalOpened: { type: Boolean, default: false },
  },
  data() {
    return {
      error: {},
      categoryModalOpened: false,
      updateCategoryMode: false,
      categoryLoading: false,
      deletingCategory: false,
      selectedCategoryId: null,
      categoryName: '',
    }
  },
  computed: {
    selectedCategory() {
      return this.categories.find(ter => ter.id === this.selectedCategoryId)
    },
    categories() {
      return this.$store.getters['earnings/categories']
    },
  },
  watch: {
    modalOpened(opened) {
      this.categoryModalOpened = opened
    },
  },
  methods: {
    // category
    resetCategoryForm() {
      this.categoryName = ''
      this.selectedCategoryId = null
      this.updateCategoryMode = false
      this.$refs.categoryForm.reset()
    },
    populateCategoryForm(category) {
      if (this.selectedCategory) {
        this.updateCategoryMode = true
        this.categoryName = this.selectedCategory?.name
      } else {
        this.resetCategoryForm()
      }
    },
    closeCategoryModal() {
      this.categoryModalOpened = false
      this.$emit('modalClosed')
    },
    updateCategory() {
      this.error = {}
      if (!this.selectedCategoryId) {
        return
      }
      this.categoryLoading = true
      this.$http.put(`finance/categories/${this.selectedCategoryId}`,{ id: this.selectedCategoryId, name: this.categoryName }).then(res => {
        this.showSuccess('Updated category successfully')
        this.categoryLoading = false
        this.$store.commit('earnings/updateCategory', res.data)
      }).catch(err => {
        logData(err)
        this.error = err
        this.showError('Failed to update category')
        this.categoryLoading = false
      })
    },
    createCategory() {
      this.error = {}
      this.categoryLoading = true
      this.$http.post('finance/categories',{ name: this.categoryName }).then(res => {
        this.showSuccess('Created category successfully')
        this.categoryLoading = false
        this.$store.commit('earnings/addCategory', res.data)
        this.resetCategoryForm()
      }).catch(err => {
        logData(err)
        this.error = err
        this.categoryLoading = false
      })
    },
    deleteCategory() {
      if (!this.selectedCategoryId) {
        this.showInfo('Please select a category')
        return
      }
      this.deletingCategory = true
      this.$http.delete(`finance/categories/${this.selectedCategoryId}`).then(() => {
        this.deletingCategory = false
        this.showSuccess('Deleted Successfully')
        this.$store.commit('earnings/removeCategory', this.selectedCategoryId)
        this.selectedCategoryId = null
        this.resetCategoryForm()
      }).catch(() => {
        this.deletingCategory = false
      })
    },

  },
}
</script>

<template>
  <div class="academic-years">
    <lenon-list-page
      title="Expenses"
      :columns="columns"
      :rows="expenses"
      :table-loading="tableLoading"
      :show-create="false"
      :show-refresh="false"
      :show-search="true"
      :show-details="true"
      search-placeholder="Search"
    >
      <template slot="table-header">
        <div
          v-responsive="$store.getters['app/smallScreen']"
          class="col-12"
        >
          <b-collapse
            id="expense"
          >
            <div class="row">
              <div class="col-md-6">
                <lenon-select
                  v-model="selectedAcademicYearId"
                  placeholder="Academic Year"
                  :options="academicYears"
                  label-name="name"
                  value-name="id"
                />
              </div>
              <div class="col-md-6">
                <lenon-select
                  v-model="selectedTermId"
                  placeholder="Select Term"
                  :options="filteredTerms"
                  label-name="name"
                  value-name="id"
                />
              </div>
              <div class="col-md-6">
                <lenon-button
                    icon="SettingsIcon"
                    label="Categories"
                    tool-tip-text="Manage Expense Categories(CRUD)"
                    class="mb-1"
                    variant="outline-primary"
                    @onClick="showExpenseCategoryModal()"
                />
              </div>
              <div class="col-md-6">
                <lenon-button
                  icon="SettingsIcon"
                  label="Expenses"
                  tool-tip-text="Manage Expenses(CRUD)"
                  class="mb-1"
                  @onClick="showExpensesModal()"
                />
              </div>
            </div>
          </b-collapse>
        </div>
        <div class="col-12">
          <div class="row mb-1">
            <div class="col-md-4">
              <strong>Total Expenses:
                <b-badge
                  variant="light-primary"
                  style="font-size: 15px;"
                >
                  <small>{{ $store.getters['auth/currency'] }}</small>{{ totalExpenses | currency }}
                </b-badge>
              </strong>
            </div>
          </div>
        </div>
      </template>
      <template slot="right-extra-header-actions">
        <div
          v-responsive="$store.getters['app/largeScreen']"
          class="d-flex align-self-center align-items-start"
        >
          <lenon-select
            v-model="selectedAcademicYearId"
            placeholder="Academic Year"
            :options="academicYears"
            label-name="name"
            value-name="id"
            class="mr-1"
          />
          <lenon-select
            v-model="selectedTermId"
            placeholder="Select Term"
            :options="filteredTerms"
            label-name="name"
            value-name="id"
            class="mr-1"
          />
          <lenon-button
              icon="SettingsIcon"
              label="Categories"
              tool-tip-text="Manage Expense Categories(CRUD)"
              class="mr-1"
              variant="outline-primary"
              @onClick="showExpenseCategoryModal()"
          />
          <lenon-button
            icon="SettingsIcon"
            label="Expenses"
            tool-tip-text="Manage Expenses(CRUD)"
            class=""
            @onClick="showExpensesModal()"
          />
        </div>
        <div
          v-responsive="$store.getters['app/smallScreen']"
          class="d-flex align-self-center align-items-center"
        >
          <lenon-button
            v-b-toggle.expense
            icon-only
            icon="MoreVerticalIcon"
            variant="flat-primary"
          />
        </div>
      </template>
      <template #category="{row}">
        {{ getCategory(row.item.expenses_category_id) }}
      </template>
      <template #amount="{row}">
        {{ row.item.amount | currency }}
      </template>
      <template #index="{row}">
        {{ row.index + 1 }}
      </template>
    </lenon-list-page>

    <expenses-setup
      :modal-opened="expensesModalOpened"
      @modalClosed="closeModal('expenses')"
    />
    <expense-category-setup :modal-opened="expenseCategoryModalOpened" @modalClosed="closeModal('category')"/>
  </div>
</template>

<script>
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import { FETCH_EXPENSES_Q, FETCH_OTHER_INCOMES_Q } from '@/graphql/queries'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import logData from '@/libs/log'
import ExpensesSetup from '@/views/finance/expenses/ExpensesSetup.vue'
import LenonDropdown from '@/lenon/components/LenonDropdown'
import LenonDropdownItem from '@/lenon/components/LenonDropdownItem'
import { VBToggle, BCollapse, BBadge } from 'bootstrap-vue'
import LenonSelect from '@/lenon/components/LenonSelect'
import ExpenseCategorySetup from '@/views/finance/expenses/ExpenseCategorySetup'

export default {
  name: 'Expenses',
  components: {
    ExpenseCategorySetup,
    LenonSelect,
    LenonDropdownItem,
    LenonDropdown,
    LenonListPage,
    ExpensesSetup,
    LenonButton,
    BBadge,
    BCollapse,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [showToast],
  data() {
    return {
      expensesModalOpened: false,
      tableLoading: false,
      selectedAcademicYearId: null,
      selectedTermId: null,
      expenseCategoryModalOpened: null,
      totalExpenses: 0.00,
      columns: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'title',
          label: 'Title',
        },
        {
          key: 'category',
          label: 'Category'
        },
        {
          key: 'amount',
          label: `Amount (${this.$store.getters['auth/currency']})`,
        },
        {
          key: 'date',
          label: 'Date',
        },
        {
          key: 'details',
          label: 'Details',
        },
      ],
    }
  },
  computed: {
    categories(){
      return this.$store.getters['earnings/categories']
    },
    expenses() {
      return this.$store.getters['earnings/expenses']
    },
    terms() {
      return this.$store.getters['termsClasses/terms']
    },
    academicYears() {
      return this.$store.getters['termsClasses/academicYears']
    },
    filteredTerms() {
      return this.terms.filter(t => this.termIds.includes(t.id))
    },
    termIds() {
      if (this.academicYear) {
        return this.academicYear.terms.map(t => t.term_id)
      }
      return []
    },
    academicYear() {
      return this.academicYears.find(ay => ay.id === this.selectedAcademicYearId)
    },
  },
  watch: {
    selectedTermId() {
      this.fetchExpenses()
    },
    selectedAcademicYearId() {
      this.fetchExpenses()
    },
    expenses(val) {
      this.totalExpenses = 0.00
      for (let i = 0; i < val.length; i++) {
        this.totalExpenses += val[i].amount
      }
    },
  },
  mounted() {
    this.fetchExpenses()
    this.getCategories()
  },
  methods: {
    getCategory(id){
      const cat = this.categories.find((c)=>c.id===id)
      if(cat){
        return cat.name
      }
      return "N/A"
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'expenses':
          this.expensesModalOpened = false
          break
        case 'category':
          this.expenseCategoryModalOpened = false
          break
      }
    },
    showExpensesModal() {
      this.expensesModalOpened = true
    },
    showExpenseCategoryModal() {
      this.expenseCategoryModalOpened = true
    },
    getCategories(){
      this.$http.get('finance/categories').then((res)=>{
        this.$store.commit('earnings/setCategories')
      })
    },
    fetchExpenses() {
      this.tableLoading = true
      this.$apollo.query({
        query: FETCH_EXPENSES_Q,
        variables: {
          term_id: this.selectedTermId,
          academic_year_id: this.selectedAcademicYearId,
        },
      })
        .then(res => {
          this.$store.commit('earnings/setExpenses', res.data)
        })
        .catch(err => {
          logData(err)
          this.showError('Failed to load fee expenses')
        }).finally(() => {
          this.tableLoading = false
        })
    },
  },
}
</script>
